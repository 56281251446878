<template>
    <div>
        <!-- <a-select v-model="tags" :options="tagOptions" mode="multiple" /> -->
		<a-select
			mode="multiple"
			v-model="selectedTags"
			style="width: 100%"
		>
			<a-select-option v-for="(tag,tagI) in tags" :key="tag+tagI" :value="tag.id">
				<b>{{tag.name}}</b> <span style="font-style: italic;"> from {{tag.instName}}</span>
			</a-select-option>
		</a-select>
    </div>
</template>

<script>
export default {
    props:{
        value:{
            type:Array,
            default:() => []
        },
        multiple:{
            type:Boolean,
            default:false,
        },
        from:{
            type:String,
            default:'',
        }
    },
    data:( () => ({
        tags:[],
		selectedTags:[]
    })),
    watch:{
		selectedTags:{
            handler(val,oldVal){
				console.log('Selected Tags', val)
                return this.$emit('input', val)
            }
        }
    },
    computed:{
		instances(){
			let {children = [],...instance} = this.$store.state.instance
            let options =  [instance,...children]
            return options.map( ({id,name}) => ({
                label:name,
                value:id
            }))
		},
    },
    created(){
        if (this.value && this.value.length){
			this.value = this.value.map(x => x = x.replaceAll('"', ''))
            this.selectedTags = this.value
        }
        console.log('INSTANCESSSSS', this.instances)
        this.tags = []
        this.instances.forEach(x => {
            this.$api.get(`/tags/${x.value}`).then( ({data}) => {
                console.log('TAG DATA COMING BACK', data)
                data.forEach(y => {
                    if (y.type = "lots"){
                        y.instName = x.label
                        this.tags.push(y)
                    }
                })
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        })
    }
}
</script>

<style>

</style>
